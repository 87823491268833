import { storeToRefs } from 'pinia';
import { useSiteStore } from '@/stores/site';
import { usePaymentStore } from '@/stores/payment';
import { PageType } from '@/types';

export const ProviderSdks = {
  init() {
    const { providers, isPreview, currentPage } = storeToRefs(useSiteStore());
    const paymentStore = usePaymentStore();
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };
    const isPaymentPage = currentPage.value?.template?.name === PageType.PAYMENT;

    if (!providers.value || !isPaymentPage) return headScripts;

    if (providers.value?.applePay && !isPreview.value) {
      const script = {
        async: true,
        crossorigin: true,
        body: true,
        src: 'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js'
      };
      headScripts.script.push(script);
    }

    if (providers.value?.googlePay && !isPreview.value) {
      const script = {
        async: true,
        body: true,
        onload: () => paymentStore.onGooglePayLoaded(),
        src: 'https://pay.google.com/gp/p/js/pay.js'
      };
      headScripts.script.push(script);
    }

    return headScripts;
  }
};
